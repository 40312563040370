import {environment} from '../../../environment/environment';

export const MAIN = {
    title: 'BREADCRUMBS_MAIN',
    link: '/'
};

export const CREDIT = {
    title: 'BREADCRUMBS_CREDIT',
    link: '/credit'
};

export const CARDS = {
    title: 'BREADCRUMBS_CARDS',
    link: '/cards'
};

export const OTHER_CARDS = {
    title: 'BREADCRUMBS_OTHER_CARDS',
    link: '/cards/archive'
};

export const QUICK_CASH = {
    title: 'BREADCRUMBS_QUICK_CASH',
    link: '/credit/quick_cash'
};

export const SALARY = {
    title: 'BREADCRUMBS_SALARY',
    link: '/credit/gdz'
};

export const PAWNSHOP = {
    title: 'BREADCRUMBS_PAWNSHOP',
    link: '/credit/pawnshop'
};

export const PBP = {
    title: 'BREADCRUMBS_PBP',
    link: '/credit/pbp'
};

export const INSTANT_INSTALLMENT = {
    title: 'BREADCRUMBS_INSTANT_INSTALLMENT',
    link: '/credit/instant_installment'
};

export const INSTALLMENT = {
    title: 'BREADCRUMBS_INSTALLMENT',
    link: '/credit/installment'
};

export const NEW_INSTALLMENT = {
    title: 'BREADCRUMBS_NEW_INSTALLMENT',
    link: '/credit/new_installment'
};

export const INSTALLMENT_FROM_STATEMENT = {
    title: 'BREADCRUMBS_INSTALLMENT_FROM_STATEMENT',
    link: '/credit/installment_from_statement'
};

export const VYGODA_INSTALLMENT = {
    title: 'BREADCRUMBS_VYGODA_INSTALLMENT',
    link: '/credit/vygoda'
};

export const MONEY_AUTO = {
    title: 'BREADCRUMBS_MONEY_AUTO',
    link: '/credit/money_auto'
};

export const DEPOSIT_CREDIT_ORDINARY = {
    title: 'BREADCRUMBS_DEPOSIT_CREDIT_ORDINARY',
    link: '/credit/deposit_credit'
};

export const SERVICES = {
    title: 'BREADCRUMBS_SERVICES',
    link: '/services'
};

export const PAYMENTS = {
    title: 'BREADCRUMBS_PAYMENTS',
    link: '/services/payments'
};

export const ABANK24 = {
    title: 'BREADCRUMBS_ABANK24',
    link: '/services/abank'
};
export const ABROAD = {
    title: 'BREADCRUMBS_ABROAD',
    link: '/services/if-you-are-abroad'
};
export const WAR_TIME = {
    title: 'BREADCRUMBS_WAR_TIME',
    link: '/services/war-time'
};

export const TRANSFERS = {
    title: 'BREADCRUMBS_TRANSFERS',
    link: '/services/transfers'
};

export const APPLE_PAY = {
    title: 'BREADCRUMBS_APPLE_PAY',
    link: '/services/apple_pay'
};

export const GOOGLE_PAY = {
    title: 'BREADCRUMBS_GOOGLE_PAY',
    link: '/services/google_pay'
};

export const SHARE_CREDIT_CARD = {
    title: 'BREADCRUMBS_SHARE_CREDIT_CARD',
    link: '/services/share'
};

export const INVITE_FRIEND = {
    title: 'BREADCRUMBS_INVITE_FRIEND',
    link: '/services/invite_friend'
};

export const CARDS_DESIGN = {
    title: 'BREADCRUMBS_CARDS_DESIGN',
    link: '/services/digital_skins'
};

export const SAFE_BOXES = {
    title: 'BREADCRUMBS_SAFE_BOX',
    link: '/services/safe_boxes'
};

export const WELSEND = {
    title: 'BREADCRUMBS_WELSEND',
    link: '/services/transfers/welsend'
};

export const RIA = {
    title: 'BREADCRUMBS_RIA',
    link: '/services/transfers/ria'
};

export const WESTERN_UNION = {
    title: 'BREADCRUMBS_WESTERN_UNION',
    link: '/services/transfers/wu'
};

export const SWIFT = {
    title: 'BREADCRUMBS_SWIFT',
    link: '/services/transfers/swift'
};

export const MONEYGRAM = {
    title: 'BREADCRUMBS_MONEYGRAM',
    link: '/services/transfers/moneygram'
};
export const SEPA = {
    title: 'BREADCRUMBS_SEPA',
    link: '/services/transfers/sepa'
};
export const INTEL_EXPRESS = {
    title: 'BREADCRUMBS_INTEL_EXPRESS',
    link: '/services/transfers/intel-express'
};

export const GREEN_CARD = {
    title: 'BREADCRUMBS_GREEN_CARD',
    link: '/cards/green'
};

export const YELLOW_CARD = {
    title: 'BREADCRUMBS_YELLOW_CARD',
    link: '/cards/yellow'
};

export const ATB_CARD = {
    title: 'BREADCRUMBS_ATB_CARD',
    link: '/cards/atb'
};

export const PLATINUM_CARD = {
    title: 'BREADCRUMBS_PLATINUM_CARD',
    link: '/cards/premium'
};

export const PROFIT_CARD = {
    title: 'BREADCRUMBS_PROFIT_CARD',
    link: '/cards/vygoda'
};

export const REPAIR_CARD = {
    title: 'BREADCRUMBS_REPAIR_CARD',
    link: '/cards/eVidnovlennia'
};

export const KID_CARD = {
    title: 'BREADCRUMBS_KID_CARD',
    link: '/cards/kid-card'
};

export const CASHBACK_UA = {
    title: 'CASHBACK_UA_LIST_TITLE',
    link: '/cards/cashback_ua'
};

export const DIIA_CARD = {
    title: 'DIIA_CARD_LIST_TITLE',
    link: '/cards/diia'
};

export const BOLT_CARD = {
    title: 'BOLT_CARD_LIST_TITLE',
    link: '/cards/bolt'
};

export const GOLD_CARD = {
    title: 'BREADCRUMBS_GOLD_CARD',
    link: '/cards/gold'
};

export const KUN_CARD = {
    title: 'BREADCRUMBS_KUN_CARD',
    link: '/cards/kun'
};

export const KDV_CARD = {
    title: 'BREADCRUMBS_KDV_CARD',
    link: '/cards/kdv'
};
export const BUSINESS = {
    title: 'BREADCRUMBS_BUSINESS',
    link: '/business'
};
export const DEPOSIT_CREDIT = {
    title: 'BREADCRUMBS_DEPOSIT_CREDIT',
    link: '/business/deposit-credit'
};
export const BUSINESS_CREDIT_LINE = {
    title: 'BUSINESS_MAIN_CREDIT_LINE',
    link: '/business/creditline'
};
export const BUSINESS_A_EXPRESS = {
    title: 'BUSINESS_MAIN_A_EXPRESS',
    link: '/business/a-express'
};
export const BUSINESS_RKO = {
    title: 'BUSINESS_MAIN_CREDIT_LIMIT',
    link: '/business/limit'
};
export const BUSINESS_ABUSINESS = {
    title: 'BUSINESS_MAIN_ABUSINESS_HEADER_TITLE',
    link: 'https://ab.a-bank.com.ua/',
    target: '__blank'
};

export const BUSINESS_FOP = {
    title: 'BREADCRUMBS_BUSINESS_FOP',
    link: '/business/fop'
};

export const BUSINESS_YUO = {
    title: 'BREADCRUMBS_BUSINESS_YUO',
    link: '/business/yuo'
};
export const BUSINESS_DEPOSITS = {
    title: 'BUSINESS_MAIN_DEPOSITS',
    link: '/business/deposits'
};
export const BUSINESS_GUARANTEES = {
    title: 'BUSINESS_MAIN_BANK_GUARANTEES',
    link: '/business/guarantees'
};

export const BUSINESS_GOV = {
    title: 'GOV_BANNER_TITLE',
    link: '/business/gov'
};

export const ENERGY_ALL = {
    title: 'ENERGY_MAIN_HEADER_TITLE',
    link: '/business/energy_all'
};
export const BUSINESS_ACQUIRING = {
    title: 'BUSINESS_TRADE_ACQUIRING_TITLE',
    link: '/business/acquiring'
};
export const BUSINESS_SALARY_PROJECT = {
    title: 'BUSINESS_SALARY_PROJECT',
    link: '/business/salary-project'
};
export const BUSINESS_VED_CONTRACT = {
    title: 'BREADCRUMBS_BUSINESS_VED',
    link: '/business/ved-contract'
};
export const BUSINESS_COLLECTION = {
    title: 'COLLECTION_BREADCRUMBS',
    link: '/business/collection'
};
export const NEWS = {
    title: 'BREADCRUMBS_NEWS',
    link: '/news'
};

export const ARCHIVE = {
    title: 'BREADCRUMBS_ARCHIVE',
    link: '/archive'
};

export const FGVO = {
    title: 'BREADCRUMBS_FGVO',
    link: '/fgvo'
};

export const SPECIAL_OFFERS = {
    title: 'BREADCRUMBS_SPECIAL_OFFERS',
    link: '/special_offers'
};

export const MASTER_CARD_SO = {
    title: 'MCSO_BREADCRUMBS',
    link: '/special_offers/master-card-special-offer'
};

export const ALWAYS_TOUCH = {
    title: 'BREADCRUMBS_ALWAYS_TOUCH',
    link: '/always-touch'
};
export const PROJECT_SUPPORT = {
    title: 'BREADCRUMBS_PROJECT_SUPPORT',
    link: '/project-support'
};
export const WORLD_ELECTRONIC_WALLETS = {
    title: 'BREADCRUMBS_WORLD_ELECTRONIC_WALLETS',
    link: '/world-electronic-wallets'
};

export const SECURITIES = {
    title: 'BREADCRUMBS_SECURITIES',
    link: '/security'
};

export const CONTACTS = {
    title: 'BREADCRUMBS_CONTACTS',
    link: '/contacts'
};

export const BRANCHES = {
    title: 'BREADCRUMBS_BRANCHES',
    link: '/branches'
};

export const SAFETY = {
    title: 'BREADCRUMBS_SAFETY',
    link: '/safety'
};

export const ABOUT_BANK = {
    title: 'BREADCRUMBS_ABOUT_BANK',
    link: '/about'
};

export const INSURANCE = {
    title: 'BREADCRUMBS_INSURANCE',
    link: '/services/insurance'
};

export const CAREER = {
    title: 'BREADCRUMBS_CAREER',
    link: '/career'
};

export const DOCUMENTS = {
    title: 'BREADCRUMBS_DOCUMENTS',
    link: '/services/document'
};

export const SERVICE_LEASING = {
    title: 'SERVICE_MAIN_LEASING',
    link: '/services/auto-leasing'
};

export const PENSION = {
    title: 'BREADCRUMBS_PENSION',
    link: '/services/pension'
};

export const ABOUT_BANK_GENERAL = {
    title: 'HEADER_LINK_GENERAL',
    link: '/about/general'
};

export const ABOUT_BANK_OWNERS = {
    title: 'HEADER_LINK_OWNERS',
    link: '/about/owners'
};

export const ABOUT_BANK_REPORTS = {
    title: 'HEADER_LINK_REPORTS',
    link: '/about/reports'
};

export const ABOUT_BANK_TOP = {
    title: 'HEADER_LINK_TOP',
    link: '/about/top'
};

export const ABOUT_BANK_STRATEGY = {
    title: 'HEADER_LINK_STRATEGY',
    link: '/about/strategy'
};

export const SERVICES_TRANSFERS = {
    title: 'TRANSFERS_TITLE',
    link: '/services/transfers'
};

export const INVITE_FRIEND_MENU = {
    title: 'SERVICE_MAIN_INVITE_FRIEND_MENU',
    link: '/services/invite_friend'
};

export const DEPOSITS = {
    title: 'BUSINESS_MAIN_DEPOSITS',
    link: '/deposits'
};

export const GADGETOMANIA = {
    title: 'GADGETOMANIA_TITLE',
    link: '/gadgetomania'
};

export const TRANSFER_BY_PHONE = {
    title: 'TRANSFER_BY_PHONE_BREADCRUMBS',
    link: '/services/transfers/transfer_by_phone'
};

export const P2P_CROSSBORDER = {
    title: 'P2P_CROSSBORDER_BREADCRUMBS',
    link: '/services/transfers/P2Pcrossborder'
};

export const UKRANIANS_TOGETHER = {
    title: 'UKRANIANS_TOGETHER_BREADCRUMBS',
    link: '/cards/ukrainians_together'
};

export const PBP_ENTITY = {
    title: 'BREADCRUMBS_PBP_ENTITY',
    link: '/business/pbp-entity'
};

export const SERVICSE_FOOTBALL = {
    title: 'FOOTBALL_BREADCRUMBS',
    link: '/services/football'
};
export const PESSONAL_DATA = {
    title: 'PERSONAL_DATA_BREADCRUMBS',
    link: '/services/personal_data'
};

export const DE = {
    title: 'LTD_DE',
    link: '/DE'
};

export const PR = {
    title: 'LTD_PR',
    link: '/PR'
};

export const DF = {
    title: 'LTD_DF',
    link: '/DF'
};

export const KE = {
    title: 'LTD_KE',
    link: '/KE'
};

export const AK = {
    title: 'LTD_AK',
    link: '/AK'
};

export const APLLE_SUMMER = {
    title: 'APLLE_SUMMER',
    link: '/apple-summer'
};
export const CONTACT_LESS = {
    title: 'BREADCRUMBS_CONTACT_LESS',
    link: '/special_offers/contact-less'
};

export const ACCEPTING_PAYMENTS = {
    title: 'BREADCRUMBS_ACCEPTING_PAYMENTS',
    link: '/business/accepting-payments'
};

export const ABANK_5_YEARS = {
    title: 'ABANK_5_YEARS_BREADCRUMBS',
    link: '/5YearsABank'
};

export const RENTIER_DEPOSIT = {
    title: 'RENTIER_DEPOSIT_BREADCRUMBS',
    link: '/static/deposit/rentier_terms.pdf',
    target: '_blank'
};

export const TRANSFER_BONUS = {
    title: 'TRANSFER_BONUS_DEPOSIT_BREADCRUMBS',
    link:
        environment.url +
        '/backend/api/v1/pages/media/file?path=deposit/transfer-bonus/transfer-bonus.pdf',
    target: '_blank'
};

export const ONLINE_DEPOSIT = {
    title: 'ONLINE_DEPOSIT_BREADCRUMBS',
    link: '/deposits#frame'
};

export const CALCULATOR_DEPOSIT = {
    title: 'CALCULATOR_DEPOSIT_BREADCRUMBS',
    link: '/deposits#frame',
    target: '_self'
};

export const TARIFFS_DEPOSIT = {
    title: 'TARIFFS_DEPOSIT_BREADCRUMBS',
    link:
        'https://conditions-and-rules.a-bank.com.ua/media/uploads/Depo/Taryf_vklady_fiz_osib_ua.pdf',
    target: '_blank'
};

export const PAYMENT_BY_PHONE = {
    title: 'PAYMENT_BY_PHONE_BREADCRUMBS',
    link: '/services/mobilepay'
};

export const CASHBACK = {
    title: 'CASHBACK_TITLE',
    link: '/services/become-partner'
};

export const TERMINALS = {
    title: 'TSO_HEADER_TEXT',
    link: '/self_service_terminal'
};
